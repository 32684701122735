/**
 * 项目看板路由配置
 */
export default{
  path: "/system",
  name: 'system',
  meta: { title: "用户" },
  component: () => import("@/views/layout.vue"),
  children: [
    {
      path: '/user/list',
      name: 'userList',
      component: () =>import("@/views/system/user/list.vue"),
      meta: {
        breadcrumb: [
          {
            label: '用户',
            value: null
          }
        ]
      }
    },
    {
      path: '/menuPermission/list',
      name: 'menuPermission',
      component: () =>import("@/views/system/menu/list.vue"),
      meta: {
        breadcrumb: [
          {
            label: '菜单权限',
            value: null
          }
        ]
      }
    },
    {
      path: '/role/list',
      name: 'role',
      component: () =>import("@/views/system/role/list.vue"),
      meta: {
        breadcrumb: [
          {
            label: '角色权限',
            value: null
          }
        ]
      }
    },
    {
      path: '/label/list',
      name: 'label',
      component: () =>import("@/views/system/label/list.vue"),
      meta: {
        breadcrumb: [
          {
            label: '标签管理',
            value: null
          }
        ]
      }
    },
    {
      path: '/icon/list',
      name: 'icon',
      component: () =>import("@/views/system/icon/list.vue"),
      meta: {
        breadcrumb: [
          {
            label: '图标管理',
            value: null
          }
        ]
      }
    }
  ]
};
