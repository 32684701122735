import { message, Modal, Empty } from "ant-design-vue";
import mitt from 'mitt';

const bus = mitt();
/**
 * 判断是图片还是视频格式
 * @param fileName 
 * @returns 
 */
function judgeMediaType(fileName: String) {
  // 后缀获取
  var suffix = "";
  // 获取类型结果
  var result: unknown = "";

  try {
    var fileArr = fileName.split(".");
    suffix = fileArr[fileArr.length - 1];
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  // 图片格式
  var imgList = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];

  // 进行图片匹配
  result = imgList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "image";
    return result;
  }
  // 匹配txt
  var txtList = ["txt"];
  result = txtList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "txt";
    return result;
  }
  // 匹配 excel
  var excelList = ["xls", "xlsx"];
  result = excelList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "excel";
    return result;
  }
  // 匹配 word
  var wordList = ["doc", "docx"];
  result = wordList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "word";
    return result;
  }
  // 匹配 pdf
  var pdfList = ["pdf"];
  result = pdfList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "pdf";
    return result;
  }
  // 匹配 ppt
  var pptList = ["ppt"];
  result = pptList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "ppt";
    return result;
  }
  // 匹配 视频
  var videoList = ["mp4", "m2v", "mkv"];
  result = videoList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "video";
    return result;
  }
  // 匹配 音频
  var radioList = ["mp3", "wav", "wmv"];
  result = radioList.some(function (item) {
    return item == suffix;
  });
  if (result) {
    result = "radio";
    return result;
  }
  // 其他 文件类型
  result = "other";

  return result;
}

/**
 * 成功提示
 * @param val 
 */
function successMessage(val: string) {
  message.success(val);
}

/**
 * 错误提示
 * @param val 
 */
function errorMessage(val: string) {
  message.error(val);
}

/**
 * 校验手机号
 * @param val 
 */
function validatorPhone(val: string) {
  let reg = /^1[3456789]\d{9}$/;
  return reg.test(val);
}

/**
 * 校验登录密码   同时包含英文字母（区分大小写）、阿拉伯数字和常规符号（英文符号） 至少包含2种字符
 * @param val 
 * @returns
 */
function validatorPassword(val: string){
  let reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;
  return reg.test(val);
}


function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


// 根据基础颜色生成由浅至深的颜色
function generateColors() {
  return {
    color: ['rgba(41, 122, 204, 1)', 'rgba(41, 68, 204, 1)', 'rgba(122, 41, 204, 1)', 'rgba(204, 41, 150, 1)', 'rgba(204, 41, 41, 1)', 'rgba(166, 99, 33, 1)', 'rgba(128, 111, 26, 1)', 'rgba(111, 128, 26, 1)'],
    bgColor: ['rgba(229, 241, 254, 1)', 'rgba(229, 233, 254, 1)', 'rgba(241, 229, 254, 1)', 'rgba(254, 229, 246, 1)', 'rgba(254, 229, 229, 1)', 'rgba(254, 241, 229, 1)', 'rgba(247, 243, 223, 1)', 'rgba(243, 247, 223, 1)'],
    hoverColor: ['rgba(216, 235, 254, 1)', 'rgba(216, 222, 254, 1)', 'rgba(235, 216, 254, 1)', 'rgba(254, 216, 241, 1)', 'rgba(254, 216, 216, 1)', 'rgba(254, 235, 216, 1)', 'rgba(242, 236, 206, 1)', 'rgba(236, 242, 206, 1)'],
    borderColor: ['rgba(122, 176, 229, 1)', 'rgba(122, 140, 229, 1)', 'rgba(176, 122, 229, 1)', 'rgba(229, 122, 193, 1)', 'rgba(229, 122, 122, 1)', 'rgba(229, 176, 122, 1)', 'rgba(197, 183, 113, 1)', 'rgba(118, 128, 73, 1)']
  };
}


// clear
function clearObject(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      delete obj[key];
    }
  }
}

export default {
  judgeMediaType,
  successMessage,
  errorMessage,
  bus,
  Modal,
  Empty,
  validatorPhone,
  validatorPassword,
  getBase64,
  generateColors,
  clearObject
};
